<template>
  <v-container fluid>
    <TeamInviteDialog v-model="showInviteDialog" />
    <v-row>
      <v-col cols="12">
        <div class="pl-4 pr-4">
          <v-btn
            target="_blank"
            block
            color="primary"
            x-large
            @click="showInviteDialog = !showInviteDialog"
          >
            {{ $t('addToTeam') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <TeamGrid
      :team="team"
      class="pl-4 pr-4"
    />
  </v-container>
</template>

<script>

import TeamGrid from "@/components/TeamGrid"
import TeamInviteDialog from "@/components/TeamInviteDialog"

export default {
	name: 'Staff',
	components: {
		TeamGrid,
		TeamInviteDialog
	},
	data: () => ({
		showInviteDialog: false
	}),
	methods: {
	},
	computed: {
		user: function() {
			return this.$store.state.user
		},
		team: function() {
			return this.$store.state.team
		}
	}
}
</script>
